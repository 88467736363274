DOMComponent    = require('shared/components/base.js').default
Template        = require('./search_progressbar.monk')
stickySetter    = require('shared/lib/sticky_setter.js').default
utils           = require('shared/lib/utils.coffee')
filters         = require('shared/lib/filters.coffee')
colorUtils      = require('shared/lib/color_utils.js').default
PoweredBy       = require('shared/components/powered_by/powered_by').default

class SearchProgressbar extends DOMComponent
  @instance: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @broadcast: (args...) -> super(args...) #HACK static inheritance in CoffeeScript
  @defaultOptions: () -> {
    name: 'search_progressbar'
    render: { template: Template },
    cssx:{
      scope: '.TPWL-widget .search_progressbar',
      styles: {
        ".progress-bar":
          "background-color": window.TPWLCONFIG.color_scheme.btn_bg
        "":
          "background": window.TPWLCONFIG.color_scheme.body_bg
          "border-bottom": "1px solid #{colorUtils.shadeBlend(0.03, window.TPWLCONFIG.color_scheme.body_bg, '#000000')}"
      }
    }
  }

  @initializeComponent: (dispatcher) ->
    dispatcher.on('start_search', => @broadcast('onSearchStart'))
    dispatcher.on('search_failed', => @broadcast('onSearchFailed'))
    dispatcher.on('search_finished', => @broadcast('onSearchFinished'))
    dispatcher.on('systemMessageError', => @broadcast('hide'))

  constructor: (containerNode, options = {}) ->
    super(containerNode, options)
    @progressBar = @view.querySelector('[role="progress-bar"]')
    @titleText = containerNode.dataset.title
    @timerValue = 45
    @started = false
    @stickyInitialized = false
    @initPoweredBy()

  initPoweredBy: ->
    wrapper = @view.querySelector('.countdown-info')
    new PoweredBy().init(wrapper, {wrapper_styles: 'display: block; text-align: right !important; margin-top: 2px !important;'})
    if window.TPWLCONFIG.powered_by then wrapper.style.setProperty('justify-content', 'space-between');

  onSearchStart: ->
    unless @stickyInitialized
      stickySetter.addElement(this.containerNode, 'search_progressbar-container--sticky', null, 4, false, true)
      @stickyInitialized = true
    @progressBarIeAnimation() if utils.is_ie and utils.get_ie_version() == 9
    @started = true
    window.requestAnimationFrame( =>
      return unless @started
      @progressBar.classList.remove('finished-animation')
      @progressBar.classList.remove('start-animation')
      @show(@progressBar)
      @show()
      @progressBar.classList.add('start-animation')
    )
    @view.update({search_in_progress: true, title: @titleText, countdown_message: __('search_progressbar.few_seconds')})
    @update_counter(@timerValue)

  onSearchFailed: -> @stop_counter()
  onSearchFinished: -> @stop_counter()

  progressBarIeAnimation: ->
    clearInterval(progress)
    @progressBar_thin.style.width = 0
    @progressBar.style.width = 0
    progress = setInterval( =>
      @progressBar_count += 10
      if @progressBar_count > 100
        clearInterval(progress)
      else
        @progressBar_thin.style.width = @progressBar_count + '%'
        @progressBar.style.width = @progressBar_count + '%'
    , 3200)

  stop_counter: ->
    @started = false
    clearTimeout(@countdownTimeoutId)
    @progressBar.classList.remove('start-animation')
    @progressBar.classList.add('finished-animation')
    @hide()
    stickySetter.stickyChanged()

  update_counter: (seconds_to_go)->
    if seconds_to_go <= 0
      @view.update({seconds_to_go: '', countdown_message: __('search_progressbar.few_seconds')})
    else
      counter_text = filters.pluralize(seconds_to_go, __('search_progressbar.sec'))
      @view.update({seconds_to_go: seconds_to_go, countdown_message: ' ' + counter_text})

    @countdownTimeoutId = setTimeout((=> @update_counter(seconds_to_go - 1)), 1000) if seconds_to_go > 0

module.exports = { default: SearchProgressbar }
